<template>
  <v-footer id="dashboard-core-footer">
    <v-container class="my-0 py-1">
      <v-row align="center">
        <!-- <v-col
          v-for="(link, i) in links"
          :key="i"
          class="text-center mb-sm-0 mb-5"
          cols="auto"
        >
          <a
            :href="link.href"
            class="mr-0 grey--text text--darken-3"
            rel="noopener"
            target="_blank"
            v-text="link.text"
          />
        </v-col> -->

        <v-spacer class="hidden-sm-and-down" />

        <v-col class="mb-sm-0 mb-0" cols="auto">
          <a
            href="/licenses"
            class="mr-0 grey--text text--darken-3 text-caption"
            rel="noopener"
            target="_blank"
          >
            {{ $t('licenses.header') }}
          </a>
        </v-col>
        <v-spacer class="hidden-sm-and-down" />

        <v-col class="text-center mb-sm-0 mb-0" cols="auto">
          <div class="grey--text text--darken-3 text-caption">
            Version: {{ backendHash }} / {{ frontendHash }}
          </div>
        </v-col>

        <v-col cols="8" md="auto">
          <div class="text-caption pt-1 pt-md-0 text-center">
            Copyright ©2021-2022 CCL Specialty Cartons. All Rights Reserved.
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
import MetaService from '@/services/MetaService.js';
import { GIT_VERSION } from '@/frontend-version.js';

export default {
  name: 'DashboardCoreFooter',

  data: () => ({
    links: [
      {
        href: '<%= BASE_URL %>licenses.txt',
        text: 'Licenses'
      }
    ],
    frontendHash: GIT_VERSION,
    backendHash: '-'
  }),

  async mounted() {
    await this.load();
  },

  methods: {
    async load() {
      try {
        const meta = await MetaService.getMeta();
        this.backendHash = meta.git_hash;
      } catch (err) {
        this.showError(this, err);
      }
    }
  }
};
</script>

<style lang="sass">
#dashboard-core-footer
  a
    text-decoration: none
    text-transform: uppercase
</style>
