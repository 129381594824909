import request from './request';

export default {
  async getMeta() {
    let url = `/api/meta/meta/`;
    console.log('url:', url);
    let rsp = await request.request(url, 'get', {}, {});
    return rsp.data;
  }
};
